import React, { useContext } from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { appContext } from 'providers/appProvider';
import { HeroPatients } from 'components/hero';
import ExternalLink from '../codes/components/external-link';

const ReportAdverseReactionsPage = () => {
	const { appConfigs } = useContext(appContext);
	return (
		<Layout indication='patient' className='patient-contact-us'>
			<Seo pageTitle='Contact Us'
      />
			<div className='body-content-container'>
				<div className='body-content'>
          <HeroPatients
            heroImgAlt="page hero"
            className="sub-page-hero">
            <h1>Report Adverse Reactions</h1>
          </HeroPatients>
					<section>
						<Row align='center'>
							<Col xs={12}>
                <p>To report suspected adverse reactions, contact Purdue Pharma L.P. at 1‑888‑726‑7535, option 2, or FDA at 1‑800‑FDA‑1088 or{' '}
								<ExternalLink
									externalURL='http://www.fda.gov/medwatch'
									messageTitle={appConfigs.externalLinkInfo.TITLE}
									messageBody={appConfigs.externalLinkInfo.BODY}
									classNameLink='external-link'
									classNameOverlay={
										appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
									}
								>www.fda.gov/medwatch</ExternalLink>.</p>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default ReportAdverseReactionsPage;
